.back{
    background-color: rgb(128, 128, 128);
}
.privacyBox{
    
}
.circularImage{
    justify-content:center;
    margin-top: -20px;
    margin-left: 100px;
    margin-bottom: 30px;
}
.privacyHeading{
    font-size: 20px;
    font-weight: 500;
    line-height: 3;
}
.para{
    margin-top: 20px;
    line-height: 1.7;
    font-size: 18px;

}