.boxContainer {
  display: flex;
  margin-bottom: 20px;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #d8dbe0;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.08);
  text-align: center;
  min-height: 400px;
  max-width: 320px;
  border-radius: 15px;
}

.box_title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 20px;

}

.box_description {
  font-size: 16px;
  margin-bottom: 10px;
  color: #5f6863;
}

.box_price {
  font-size: 36px;
  font-weight: 700;
  margin-top: 20px;
}

.box_info {
  font-size: 14px;
  margin-top: 10px;
  color: #5f6368;
  background-color: aliceblue;
}

.box_get {
    background-color: #5184f4;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    border: none;
    border-radius: 10px;
    padding: 10px 25px;
    margin-top: 10px;
    cursor: pointer;
}

.box_list {
  margin-top: 30px;
  text-align: left !important;
}

.box_listItem{
  font-size: 12px;
  line-height: 12px;
  color: #5f6368;
  font-style: italic;
}

.box_listItem::before {
  content: '✔';
  font-size: 12px;
  margin-right: 10px;
  margin-top: 5px;
  color: #5f6368;
}
