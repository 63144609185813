:root {
    --pink: #F7DFF5;
    --blue: #0565FF;
    --lighter-blue: #AAD4FF;
    --black: #000;
    --light-black: #405261;
    --gray: #F9FAFA;
    --violet: #B12484;
    --lighter-blue: #F4F8FF;
    --light-purple: #F4F4FB;
    --light-violet: #FAECF9;
    --light-green: #eaf9eb;
    --light-yellow: #FCF7EC;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.blackBox{
    width: 86%;
    background-color: black;
    height: 300px;
    border-radius: 14px;
    padding: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.boxheading{
    margin-top: 30px;
    font-size: 45px;
    font-weight: 500;
    text-align: left;
    line-height: 75px;
}

.boxSubHeading{
    font-size: 35px;
    font-style: italic;
    font-weight: 400;
    text-align: left;
    line-height: 70px;
}

.bannerLogo {
    width: 30%;
}

.homePageHeader{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 40px;
    text-align: center;
    margin-bottom: 40px;
    background-size: cover;
    background-blend-mode: exclusion;
    background-repeat: no-repeat;
}
.mainHeading{
  margin-bottom: 30px;
}
.heroHead{
  font-size: 54px;
  font-weight: 700;
}
.subheading{
  color: #607D8B;
  font-size: 23px;
  width:40%;
}
.buttons{
  margin: 50px;
}

.promoVideo {
    margin: 0px 100px 50px;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.promoVideo iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.container{
    display: grid;
    width: 100% !important;
    background-color: transparent;
}

.child{
    margin:2px;
}

.companies_section{
  background-color: #F3F4F4;
  height: 250px;
}
.companies{
  width:80%;
 padding: 30px;
  margin-left: 150px;
  text-align: center;
}
.companies_heading{
  font-size: 22px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .blackBox {
    height: fit-content;
    display: block;
    align-items: center;
  }
  .homePageHeader{
    padding-top: 10%;
    padding-bottom: 10%;
    margin-bottom: 0%;
  }
  .heroHead{
    font-size: 30px;
    font-weight: 700;
    padding: 0% 5%;
  }
  .subheading{
    color: #607D8B;
    font-size: 16px;
    width: 60%;
  }
  .boxheading{
    margin-top: 10px;
    font-size: 25px;
    font-weight: 500;
    text-align: left;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .boxSubHeading{
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    text-align: left;
    line-height: 25px;
    margin-bottom: 40px;
  }

  .bannerLogo {
    width: 80%;
  }
}
