.smallBox {
    border-radius: 10px;
    width: 31%;
    display: flex;
    overflow: scroll;
    padding: 0% 3%;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 786px) {
    .smallBox {
        width: 100%;
    }
}

.imageWrapper {
}

.textWrapper {}

.iconSquare{
    width: 50px;
    height: 50px;
    display: flex;
    font-size: 39px;

}
.icon {
    height: 44px !important;
}

.box {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    width:86%;
    margin-left: 110px;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
    padding: 60px 70px 10px 70px;
}

.companyBox{
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* This will create a 4-column grid with equal-sized columns */
  grid-gap: 5px; /* This will add a gap between the grid items */
  background-color: red;
  margin: 5px;
  justify-content: center;
}

