.background{
    background-color: #212529;
    padding-top: 40px;
}
.social_media{
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  font-size: 20px;
  color:#FFFFFF;
  margin-top: 30px;
}

.footer_container {
    margin: 0 10%;
    color: #FFFFFF;
}

.footerMainContent {
  display: flex;
  flex-wrap: wrap;
}

.column {
  width: 25%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 786px) {
  .column {
    width: 100% !important;
  }
}

.logo {
}

ul {
  list-style: none;
}

li {
  margin: 10px 0;
  font-size: 17px !important;
  font-weight: 280 !important;
  text-align: right;
}


.head{
  font-weight: 450;
  font-size: 20px;
  text-align: right;
  margin-top: 30px;
}

.copy_right{
  margin-top: 30px;
  font-size: 13px !important;
  font-weight: 280 !important;
  display: flex;
  justify-content: flex-end;
  text-align: end;
}

.rights {
  margin-top: 5px;
  font-size: 13px !important;
  font-weight: 280 !important;
  display: flex;
  justify-content: flex-end;
}

.hide{
  display: none;
}

.appStoreLogos {
  display: flex;
  width: 100%;
  justify-content: center;
}

.storeLogo {
  width: 150px;
}

.footerLinkHover:hover{
  color: #54585e;
}
.facebook:hover{
  color: #3B5999;
}

.twitter:hover{
  color: #1F9BE9;
}
.linkedin:hover{
  color: #0A66C2;
}
.youtube:hover{
  color: #FF0000;
}
.instagram:hover{
  color:#D13F6C;
}
