.checkmark{
  color: black;
  font-size: 28px;
  padding-left: 46%;
  display: flex;
}

.crossmark {
  color: black;
  font-size: 28px;
  padding-left: 46%;
  display: flex;
}

.comparison_table {
  display: flex;
  justify-content: center;
  margin-left: 5%;
  width: 90%;
  border: 1px solid #d8d8d8;
  margin-top: 20px;
  margin-bottom: 50px;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.08);
  overflow-x: scroll;
}
.comparison_table tbody {
  overflow: auto;
}
.comparison_table th {
  border-bottom: 1px solid #d8d8d8;
  border-top: none !important;
  text-align: center;
}

.comparison_table th,
.comparison_table td {
  padding: 15px;
  border-top: 1px solid #d8d8d8;
  width: 400px;
  overflow: scroll;
}

.comparison_table td {
    font-size: 16px;
    text-align: center;
}

.comparison_table .plan-type {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  padding: 20px 0;
  border-bottom: 2px solid #d8d8d8;
  grid-column-start: 1;
  grid-column-end: 5;
  text-align: center;
}

.comparison_table .plan-name {
  font-weight: bold;
  padding-bottom: 10px;
}

.comparison_table .plan-description {
  font-size: 14px;
  color: #6c757d;
  padding-bottom: 10px;
}

.comparison_table .plan-price {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 10px;
}

.comparison_table .plan-button {
  display: block;
  margin: 20px auto;
  padding: 10px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #2dce89;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.comparison_table .plan-button:hover {
  background-color: #20a86b;
}

.comparison_table .plan-space {
  height: 20px;
  grid-column-start: 1;
  grid-column-end: 5;
  border: none;
  background-color: #f5f5f5;
}

.comparison_table .plan-line {
  border: none;
  background-color: #d8d8d8;
  grid-row-start: 1;
  grid-row-end: 8;
  margin: 0 10px;
}
.features{
  font-size: 19px;
  font-weight: 620;
  text-align: left;
}
.features_desc{
  text-align: left;
}
