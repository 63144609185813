.securityPage{
    width: 100%;
    padding: 0 10%;
    text-align: justify;
}
.securityHead{
    margin-top: 20px;
    text-align: left;
    line-height: 2;
    font-size: 35px;
    font-weight: 750;
}
.date{
    font-size: 13px;
    font-weight: 600;
    color:#75757d;
    margin: 20px;
    margin-left: 0;
}

.securityLogo{
    font-size: 14px;
    font-weight: 700;
    background-color: #F1F3F5;
    width: 100%;
    padding: 10px;
    border-radius: 30px;
    white-space: nowrap;
}

.para{
    font-size: 19px;
    line-height: 2;
}
.child_container{
    text-align: justify;
}
