.container{
    width: 80%;
    display: grid;
    column-gap: 40px;
}

@media only screen and (max-width: 786px) {
    .container {
        display: block;
    }
}
