.get_in_touch{
  width: 100%;
}

.icon {
  margin: 20px;
  font-size: 35px;
}

.contact_Details {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5%;
  padding: 40px 10%;
}

.contact_box {
  width: 45%;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f3f2f3;
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 786px) {
  .contact_box {
    width: 100%;
  }
}

.contact_box:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


.contact_heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}



.contact_us_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_us_box {
  padding: 20px;
  justify-content: center;
  align-items: center;
  height: 250px;
  bottom: 20px;
  right: 20px;
}

.contact_us_box button {
  background-color: #FFD700;
  border: none;
  border-radius: 25px;
  color: #131313;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.contact_us_box button:hover {
  background-color: #FDB813;
}

.contact_us_heading {
  font-size: 30px;
  font-weight: 550;
  margin: 0;
  text-align: center;
}

.contact_us_para {
  font-size: 1.25rem;
  text-align: center;
  margin: 10px 0 30px 0;
}

.contact_head {
  font-size: 3.5rem;
  font-weight: bold;
  margin: 10px;
  text-align: center;
  color: black;
  padding-top: 60px;
  margin-top: 50px;
}

.contact_box {
    display: flex;
    align-items: center;
    padding: 20px;
  }

.contact_details{
    height: 70vh;
}

.form{
    width: 500px;
    text-align: center;
    display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 40px;
  border: 1px solid rgb(176, 176, 176);
  box-shadow: 7px 7px 7px 0px rgba(0, 0, 0, 0.2);

}

.form_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slide-down 0.5s ease;
}


.input{
    margin: 20px;
    margin-left: 0;
    height: 40px;
    width: 408px;
    padding: 10px;
    background-color: #F3F2F3;
}
.input_msg{
    margin: 30px;
    margin-left: 0;
    height: 80px;
    width: 440px;
    padding: 10px;
    background-color: #F3F2F3;
}

.form_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slide-down 0.5s ease;
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}


.close_btn {
  position: absolute;
  float: right;
  width: 30px;
  height: 30px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 420px;
  margin-top: -20px;
}

.close_btn:hover {
  background-color: #000;
  color: #fff;
}
.submit_btn{
  background-color: #2A50E3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  text-align: center;
  justify-content: center;
}

.parent_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapWrapper {
  margin: 5% 5%;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}




