.optionsSection {
    background-color: rgb(244, 248, 255);
    margin-bottom: 3%;
    margin-top: 3%;
    margin-left: 5%;
    overflow: scroll;
    width: 90%;
    border-radius: 80px;
    padding: 30px 0px;
}

.dividerOption {
    color: #6F8FAF;
    margin-bottom: 30px;
    width: 90%;
    margin-left: 5%;
}

.optionContainer{
    display: flex;
    background-color: aqua;
    margin: 0px 70px;
    box-sizing: border-box;
}

.options {
    padding: 20px;
    display: flex;
    gap: 70px;
    justify-content: center;
}

.option {
    background-color: #FFFEFE;
    border: 2px solid #556575;
    border-radius: 20px;
    padding: 7px 10px;
    font-weight: 600;
    color: #556575;
}

.option:hover {
    background-color: #E5E9EB;
    border: 2px solid #AAAEB1;
    border-radius: 20px;
}

.optionSelected {
    background: #ecf3ff;
    border: 2px solid #0565ff !important;
    color: #0565ff !important;
}

.optionHeading {
    font-size: 35px;
    font-weight: 700;
    line-height: 3;
}

.info {
    padding: 0% 2%;
    overflow: scroll;
}

.fadeIn{
    animation: fade-in-keyframes 1s;
}
@keyframes fade-in-keyframes {
    from {opacity: 0}
    to {opacity: 1}
}

.optionImage{
    max-width: 85%;
    max-height: 350px;
    border-radius: 30px;
}

.headinfo {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 30px;
    margin-top: 30px;
}
.para {
    margin-top: 50px;
    font-size: 18px;
}

.featureDetail {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0% 6%;
    justify-items: center;
}


@media (max-width: 767px) {
    .optionsSection {
        overflow: hidden;
        padding: 10px 0px;
        border-radius: 10px;
    }
    .optionHeading {
        font-size: 25px;
        font-weight: 500;
        line-height: 2;
    }
    .options {
        padding: 10px;
        display: block;
    }
    .option {
        background-color: #FFFEFE;
        border: 2px solid #556575;
        border-radius: 20px;
        padding: 7px 10px;
        font-weight: 600;
        color: #556575;
        margin-right: 15px;
        margin-bottom: 15px;
    }
    .info {
        padding: 3% 0%;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .headinfo {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .para {
        margin-top: 10px;
        font-size: 14px;
    }
    .optionImage{
        max-height: 400px;
        border-radius: 30px;
        margin-left: 0px;
        display: block;
        margin-bottom: 20px;
    }
    .featureDetail {
        display: block;
        padding: 0px 30px;
    }
}
