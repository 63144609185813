.btn{
  margin: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;

  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;

  border: 2px solid #3c73ff;
  background-color: #3c73ff;
  border-radius: 20px;
  color: #fff;
  transition: .3s;
}

.btn:hover {
  background-color: aliceblue;
  box-shadow: 8px 8px #99bdff;
transition: .3s;
}
