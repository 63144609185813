/*black box*/
h4{
    color: #fff;
    text-align: left;
    font-size: 25px;
}
.space{
    padding: 40px;
    height: 500px;
    padding-top: 100px;
}

/*gradient box*/

h3{
    text-align: center;
}
.servicesHeading{
    font-size: 33px;
    font-weight: 600;
    padding: 30px;
}
.gradient{
    height:500px;
    width: 100%;
    padding:50px;
    background: linear-gradient(to right, #ECF3FE, #ECF3FE, #EEF0FD, #F1E9FA, #F6E5F6, #F8E2F7, #F7DFF5, #F7DFF5);
  }

/*grey box*/
.grayBackground{
    background-color: #F3F4F4;
    height: 800px;
}
.gray-background-heading{

    font-size: 35px;
    font-weight: 500;
}
.space2{
    margin-top: 80px;
    margin-bottom: 30px;

}
.light-heading{
    color: #748494;
    font-size:20px;
}
