.privacySection{
    margin-top: 100px;
    margin-bottom: 100px;
}
.blueTopBox{
    background-color: #EEF4FF;
    padding: 40px;
    text-align: center;
}
.head{
    padding-top: 20px;
    padding-bottom: 20px;
}
.boldHeading{
    font-size: 43px;
    font-weight: 630;
}
.mainSection{
    margin: 50px;
}

.childContainer{
    text-align: justify;
}
