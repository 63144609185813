.inlineContainer {
    padding: 0% 5%;
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: center;
    gap: 0px;
    margin-bottom: 10px;
    justify-items: center;
}

.imageGenericContainer {
    border-radius: 20px;
    max-height: 400px;
}

.textGenericContainer {
    padding: 5% 13%;
}

.textGenericContainer h2{
    font-size: 40px;
    font-weight: 700;
    line-height: 1.5;
}

.textGenericContainer p{
    color: #607D8B;
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.readMore {
    display: inline-block;
    color: blue;
}

.heroContainer{
    display: flex;
}

@media (max-width: 767px) {
    .heroContainer {
        display: flex;
        margin: 0px 30px 0px 30px;
        background-color: rgb(244, 248, 255);
        padding: 20px;
        margin-bottom: 40px;
        border-radius: 20px;
    }

    .textGenericContainer {
        padding: 10px;
        margin-bottom: 20px;
    }

    .imageGenericContainer {
        background-color: rgb(208, 206, 206);
        margin-bottom: 20px;
        border-radius: 20px;
        max-height: 450px;
    }

    .textGenericContainer h2{
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5;
    }

    .textGenericContainer p{
        color: #607D8B;
        font-size: 12px;
    }

    .inlineContainer {
        padding: 0px;
        display: block;
        justify-content: center;
    }
}

