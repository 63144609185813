.blueBoxContainer{
    background-color: #2255A3;
    opacity: 0.9;
    color: #fff;
    height: 300px;
    padding: 60px;
    margin-top: -1px;
}
.blueBoxContainer h3{
    font-size: 42px;
    font-weight: 650;
    text-align: left;
    margin-bottom: 13px;
}
.blueBoxContainer p{
    font-size: 20px;
    font-style: italic;
}
.About_us_margin {
    margin-top: 85px;
    margin-bottom: 110px;
}
.About_us_head{
    font-size: 32px;
    margin-right: 100px;
}
